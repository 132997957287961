// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');

require('jquery');
require('public');
require('./jquery.schedule');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// $(document).ready(function(){
//     $('html').animate({scrollTop:0}, 1);
//     $('body').animate({scrollTop:0}, 1);
// });
// require("trix")
// require("@rails/actiontext")

// var Trix  = require("trix")

// /* what the newly created button does */
// Trix.config.textAttributes.red = {
//     style: { color: "red" },
//     parser: function(element) {
//         return element.style.color === "red"
//     },
//     inheritable: true
// }

// /* insert the button visual in the default toolbar */
// addEventListener("trix-initialize", function(event) {
//     var buttonHTML = '<button class="trix-button" type="button" data-trix-attribute="red">RED</button>'

//     event.target.toolbarElement.
//     querySelector(".trix-button-group").
//     insertAdjacentHTML("beforeend", buttonHTML)
// })
